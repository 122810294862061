import React, { useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row
} from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import { HSRLightCone } from '../../modules/hsr/common/components/hsr-light-cone';
import Countdown from 'react-countdown';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const HSRIndexPage: React.FC = () => {
  const [currentRegion, setCurrentRegion] = useState('NA');
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page hsr '} game="hsr">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Honkai: Star Rail wiki and database</h1>
        <h2>
          Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse
          turn-based space fantasy RPG. Check out our guides, character reviews,
          tier list and more!
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <SectionHeader title="Promotion" />
      <div className="promo-top">
        <OutboundLink
          href="https://topup.bot/?game=hsr&utm_source=prydwen&utm_medium=referral&utm_campaign=january"
          target="_blank"
        >
          <StaticImage
            src="../../images/starrail/promo.webp"
            alt="Toptup.bot Promotion"
            className="desktop"
          />
          <StaticImage
            src="../../images/starrail/promo_mobile.webp"
            alt="Toptup.bot Promotion"
            className="mobile"
          />
          <p className="mobile text">
            <strong>Topup.bot</strong> - Oneiric Shards at a 20% discount,
            delivered instantly to UID!
          </p>
        </OutboundLink>
      </div> */}
      <SectionHeader title="Active codes" />
      <div className="codes">
        <Box isCentered>
          <p className="code">STARRAILGIFT</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 26.04.2023</p>
        </Box>
        <Box isCentered>
          <p className="code">2A3LF64ANXSX</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 26.02.2025</p>
        </Box>
        <Box isCentered>
          <p className="code">DBKMW7MA6XB3</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 19.03.2025</p>
        </Box>
      </div>
      {/* <span className="new">NEW!</span>{' '} */}
      <SectionHeader title="Play on Mac" />
      <div className="banner-hsr-mac">
        <div className="cta">
          <h4>Play Honkai: Star Rail on Mac!</h4>
          <OutboundLink href="https://bstk.me/uQP74HWSY" target="_blank">
            <Button variant="primary">Play now on Mac</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            {/* <Accordion.Item className="garden" eventKey="445">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Garden of Plenty</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1740128400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1740106800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1740081600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/01/14 04:00:00 – 2025/01/21
                  04:00:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Golden and Crimson
                  Calyx will give double rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item className="planar-fissure" eventKey="44412">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Planar Fissure</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1742374800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1742353200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1742328000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/12 04:00:00 – 2025/03/19
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Planar Ornaments
                  obtained from the immersion device will be doubled when
                  challenging the Simulated Universe. The number of daily double
                  reward opportunities is limited and can be viewed on the
                  Planar Fissure event page.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item className="realm" eventKey="444131">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Realm of the Strange</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1743757200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1743735600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1743710400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/28 04:00:00 – 2025/04/04
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, the amount of
                  Relics obtained from the Cavern of Corrosion will be doubled.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="nameless-honor" eventKey="992">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Nameless Honor</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1744016400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1743994800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1743966000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 3.1 update - 2025/04/07
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Trailblazers can
                  increase the level of Nameless Honor to obtain various
                  rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="odyssey" eventKey="998">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Gift of Odyssey</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1744102800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1744081200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1744052400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 3.1 update - 2025/04/08
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: Trailblazers can receive daily
                  rewards in the Travel Log each day after logging in to the
                  game. Rewards will be unlocked in sequential order according
                  to the accrued login days of the Trailblazer.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mydei" eventKey="3007">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Faker</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1744142400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1744120800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1744095600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/19 12:00:00 - 2025/04/08
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="mydei"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone
                    mode="profile"
                    name="Flame of Blood, Blaze My Path"
                  />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="arlan"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="natasha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="xueyi"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Perfect Timing" />
                  <HSRLightCone mode="profile" name="A Secret Vow" />
                  <HSRLightCone mode="profile" name="Memories of the Past" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="huo" eventKey="3008">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Scaredy Fox</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1744142400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1744120800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1744095600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/19 12:00:00 - 2025/04/08
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="huohuo"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Night of Fright" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="arlan"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="natasha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="xueyi"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Perfect Timing" />
                  <HSRLightCone mode="profile" name="A Secret Vow" />
                  <HSRLightCone mode="profile" name="Memories of the Past" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="apo" eventKey="104">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Apocalyptic Shadow (3.1)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1744621200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1744599600000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1744570800000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/03 04:00:00 – 2025/04/14
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When enemy units with
                  Steadfast Safeguard are Weakness Broken, all ally units
                  immediately take action and restore HP. All enemies take 25%
                  more memosprite DMG, and 15% more Skill DMG.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="pure-fiction" eventKey="105">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Pure Fiction (3.1)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1745830800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1745809200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1745780400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/17 04:00:00 – 2025/04/28
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: After an ally uses Basic ATK
                  or Ultimate to attack enemy targets, every enemy target hit
                  additionally accumulates 3 Grit Value for allies.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="memory-of-chaos" eventKey="103">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (3.1)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1747040400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1747018800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1746993600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2025/03/31 04:00:00 – 2025/05/12
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: Increases all allies' Max HP
                  by 30%. Every time an ally is attacked, restores HP equal to
                  5% of their Max HP and adds 1 hit to the Hits Per Action of
                  "Memory Turbulence." Every time an ally consumes HP, restores
                  HP equal to 10% of their Max HP and adds 2 hits to the Hits
                  Per Action of "Memory Turbulence." This effect can only
                  trigger once per attack and up to 20 hits. At the beginning of
                  each Cycle, each hit from "Memory Turbulence" deals 1 instance
                  of True DMG to a random enemy target.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header ">
            <h5>Upcoming</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            <p>Coming Soon!</p>
          </Accordion>
        </Col>
      </Row>
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/star-rail/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_introgame.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/star-rail/guides/reroll"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_rerollguide.jpg"
              alt="Reroll"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/star-rail/tier-list"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_tierlist.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/star-rail/guides/free-characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_freechars.png"
              alt="Free characters"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/star-rail/characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Early progression tips"
          link="/star-rail/guides/early-progression"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_earlyprog.png"
              alt="Early progression tips"
            />
          }
        />
        <CategoryCard
          title="Team building"
          link="/star-rail/guides/team-building"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_team.png"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Light Cones"
          link="/star-rail/guides/light-cones"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_cones_guide.png"
              alt="Light Cones"
            />
          }
        />
        <CategoryCard
          title="Relics"
          link="/star-rail/guides/relics"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_relicinfo.png"
              alt="Relics"
            />
          }
        />
        <CategoryCard
          title="Memory of Chaos Analytics"
          link="/star-rail/memory-of-chaos"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_fh.png"
              alt="Memory of Chaos"
            />
          }
        />
        <CategoryCard
          title="Pure Fiction Analytics"
          link="/star-rail/pure-fiction"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_pf.jpg"
              alt="Pure Fiction"
            />
          }
        />
        <CategoryCard
          title="Apocalyptic Shadow Analytics"
          link="/star-rail/apocalyptic-shadow"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_apo.jpg"
              alt="Apocalyptic Shadow"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Honkai: Star Rail Wiki and Database | Prydwen Institute"
    description="Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse turn-based space fantasy RPG. Check out our guides, character reviews, tier list and more!"
  />
);
